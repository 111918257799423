<template>
  <v-container fluid class="pa-0">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title overflow-auto>
        <SelectScenario v-model="scenario_id" flat hide-details filled></SelectScenario>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <MenuMore v-if="scenario_id" offset-x offset-y>
        <v-list>
          <v-list-item @click="download(scenario_id)">Download</v-list-item>
          <v-list-item color="error" @click="removeScenario(scenario_id)"> Delete </v-list-item>
        </v-list>
      </MenuMore>
    </v-toolbar>
    <v-expand-transition v-if="loadPending">
      <v-progress-linear class="mt-0" :indeterminate="true" color="secondary"></v-progress-linear>
    </v-expand-transition>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-alert v-model="showError" type="error" dismissible>{{ errorMessage }}</v-alert>
      </v-col>
      <router-view></router-view>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "ScenarioView",
  components: {
    SelectScenario: () => import("@/components/SelectScenario.vue"),
    MenuMore: () => import("@/components/MenuMore.vue"),
  },

  props: {
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      shareDialog: null,
      sharingScenario: null,
      userId: null,
      scenarioPanel: null,
    };
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      listScenarios: "scenarios/list",
      loadingCases: "cases/loadPending",
    }),

    loadPending() {
      return this.loadingCases;
    },
    scenario_id: {
      get() {
        return this.id || null;
      },
      set(id) {
        if (id != null) {
          this.setCurrentScenario(id);
          this.$router.push({
            name: "ScenarioCases",
            params: { id },
          });
        } else {
          this.clearCurrentScenario();
        }
      },
    },
    showError() {
      return this.errorMessage && this.errorMessage.length;
    },
    errorMessage() {
      if (this.$route.query.error) {
        return this.$route.query.error;
      }
      return null;
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setCurrentScenario: "scenarios/setCurrent",
      clearCurrentScenario: "scenarios/clearCurrent",
    }),
    ...mapActions({
      loadScenarios: "scenarios/find",
      download: "scenarios/downloadOutputs",
      deleteScenario: "scenarios/confirmRemove",
    }),
    removeScenario(id) {
      const removed = this.deleteScenario(id);
      if (removed) {
        this.$router.push({ name: "Scenarios" });
      }
    },
    init() {
      this.loadScenarios();
    },
  },
};
</script>

<style></style>
