<template>
  <v-expansion-panels v-model="scenarioPanel" multiple accordion>
    <v-expansion-panel v-for="(scenario, index) in scenarios" :key="scenario.name">
      <v-expansion-panel-header class="py-0">
        <v-row align="center" class="text-h6 py-0">
          <v-col cols="4" class="py-1">
            <v-subheader class="pa-0">Scenario:</v-subheader>
            {{ scenario.name }}
          </v-col>
          <v-spacer></v-spacer>
          <MenuMore offset-x offset-y>
            <v-list>
              <v-list-item color="error" @click="download(scenario.id)">Download</v-list-item>
              <v-list-item color="error" @click="removeScenario(scenario.id)">Delete</v-list-item>
            </v-list>
          </MenuMore>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-0">
        <CaseTable
          v-if="scenarioPanel.includes(index)"
          :scenario-id="scenario.id"
          :scenario-name="scenario.name"
          :cases="scenario.cases"
          class="ma-0"
        ></CaseTable>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ScenarioOverview",
  components: {
    MenuMore: () => import("@/components/MenuMore.vue"),
    CaseTable: () => import("@/components/CaseTable.vue"),
  },
  mixins: [
    makeFindMixin({
      service: "scenarios",
      watch: true,
    }),
  ],
  props: {},
  data() {
    return {
      scenarioPanel: [],
    };
  },
  computed: {
    ...mapState({
      getCurrentOrganization: (state) => (state.auth.user ? state.auth.user.organization_id : null),
    }),
    ...mapGetters({
      listScenarios: "scenarios/list",
      caseLoadPending: "cases/loadPending",
    }),
    scenariosParams() {
      return { query: { organization_id: this.getCurrentOrganization } };
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      download: "scenarios/downloadOutputs",
      removeScenario: "scenarios/confirmRemove",
    }),
  },
};
</script>

<style></style>
