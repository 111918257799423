<template>
  <v-col cols="12">
    <CaseTable :scenario-id="id" :scenario-name="scenarioName"> </CaseTable>
  </v-col>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ScenarioCases",
  components: {
    CaseTable: () => import("@/components/CaseTable.vue"),
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      getScenario: "scenarios/get",
    }),
    scenarioName() {
      return this.getScenario(this.id) ? this.getScenario(this.id).name : "";
    },
  },
  watch: {},
  mounted() {
    this.loadScenario(this.id).then(() => {
      if (!this.getScenario(this.id)) {
        this.$router.push({
          name: "Scenarios",
          replace: true,
          query: {
            error: `Scenario ID ${this.id} does not exist.`,
          },
        });
      }
    });
  },
  methods: {
    ...mapActions({
      loadScenario: "scenarios/get",
    }),
  },
};
</script>

<style></style>
